import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Heading, { Type } from "../../UI/Heading"
import Button from "../../UI/Button"

import { Section, Content, ButtonGrid } from "./styles"

const Text = ({ title, html, backgroundColor, textColor }) => {
  const intl = useIntl()
  return (
    <Section color={backgroundColor}>
      <Content color={textColor}>
        <Heading type={Type.h1}>{title}</Heading>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <ButtonGrid>
          <Button onClick={() => navigate("/")}>
            {intl.formatMessage({ id: "page.back_btn_text" })}
          </Button>
        </ButtonGrid>
      </Content>
    </Section>
  )
}

Text.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string,
}

export default Text
