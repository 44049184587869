import styled from "styled-components"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme, color }) =>
    color ? color : theme.color.backgroundSectionOne};
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 24px 24px 48px;
  }
`

export const Content = styled.div`
  color: ${({ theme, color }) => (color ? color : theme.color.black)};
  max-width: 900px;
  min-height: 600px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
  }

  div {
    font-size: 24px;
    line-height: 32px;

    @media (max-width: ${breakpoints.l}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const ButtonGrid = styled.div`
  text-align: center;
`
